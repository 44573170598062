// component
import Iconify                      from '../../components/Iconify'
import * as React                   from 'react'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
    {
      key: 'services',
      title: 'Services',
      path: '/dashboard/services',
      icon: getIcon('material-symbols:list-alt'),
    },
    {
      key: 'messages',
      title: 'Messages',
      path: '/dashboard/messages',
      icon: getIcon('material-symbols:sms'),
    },
    {
      key: 'message-statistics',
      title: 'Message statistics',
      path: '/dashboard/statistics/messages',
      icon: getIcon('material-symbols:bar-chart'),
    },
    {
      key: 'logs',
      title: 'Logs',
      path: '/dashboard/errors',
      icon: getIcon('material-symbols:error'),
    },
    {divider: true, key: 'key-divider-1'},
    {
      key: 'providers',
      title: 'Providers',
      path: '/dashboard/providers',
      icon: getIcon('material-symbols:settings-input-antenna'),
    },
    {
      key: 'categories',
      title: 'Categories',
      path: '/dashboard/categories',
      icon: getIcon('material-symbols:format-list-bulleted'),
    },
    {
      key: 'users',
      title: 'Users',
      path: '/dashboard/users',
      icon: getIcon('eva:people-fill'),
    },
    {divider: true, key: 'key-divider-0'},
    {
      key: 'phone-whitelist',
      title: 'Whitelist',
      path: '/dashboard/whitelist',
      icon: getIcon('material-symbols:person-check'),
    },
    {
      key: 'phone-blacklist',
      title: 'Blacklist',
      path: '/dashboard/blacklist',
      icon: getIcon('material-symbols:person-cancel'),
    },
]

export default navConfig
