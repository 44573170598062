import { DateTime }              from 'luxon'

const FM_VISIBLE_DATETIME = 'dd.MM.yyyy HH:mm:ss'
export function fDateTime(dateTimeStr, newFormat) {
  const fm = newFormat || FM_VISIBLE_DATETIME
  return DateTime.fromISO(dateTimeStr).setZone('local').toFormat(fm)
}
export function fPrice(price) {
  const fmtdPrice = (price / 100).toFixed(2)
  return new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(fmtdPrice)
}
export function fExportPrice(price) {
  return price / 100
}