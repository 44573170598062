import { useApi }                from '../utils/api'
import { useTranslation }        from 'react-i18next'
import React, { useState }       from 'react'
import BaseTable, { BaseSearch } from '../components/base-table'
import {
  Box, Button,
  Container,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography
}                             from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import {
  dateFromInputStringToSql, dateToInputString,
  dateToUserString,
}                             from '../utils/dateutils'
import Iconify                from '../components/Iconify'
import Page                   from '../components/Page'
import { MessageStatus }      from '../consts'
import { DateTime }           from 'luxon'

export default function ErrorsPage () {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState("")
  const [dateTimeFrom, setDateTimeFrom] = useState(dateToInputString(DateTime.now().minus({ minutes: 10 }).toISO()))
  const [dateTimeTo, setDateTimeTo] = useState(dateToInputString(DateTime.now().toISO()))
  const [showFilter, setShowFilter] = useState(true);
  const [forceReloadCounter, setForceReloadCounter] = useState(0);

  const columns = [
    { field: 'id', headerName: t('ID'), width: 120,},
    { field: 'messageId', headerName: t('Message ID'), width: 120, renderCell: (cell) => {
      return <Link component={RouterLink} to={`/dashboard/messages/${cell.row.messageId}`}>{cell.row.messageId}</Link>
      }},
    { field: 'created', headerName: t('Created'), width: 160, renderCell: (cell) => {
      return (<Typography color={'color.secondary'}>{dateToUserString(cell.row.created)}</Typography>)
    }},
    { field: 'text', headerName: t('Text'), flex: 1, editable: true, },
    { field: 'rawMessage', headerName: t('Raw message'), flex: 1, editable: true, },
  ];

  const onSubmitSearch = () => {
    setForceReloadCounter(forceReloadCounter + 1);
  }

  return (<Page title={t('Logs')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={1} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Logs')}</Typography>
        <Box flexGrow={1} />
        <IconButton onClick={(e) => {
          setShowFilter(!showFilter)
        }} >
          <Iconify icon={showFilter ? 'eva:funnel-fill' : 'eva:funnel-outline'} />
        </IconButton>
      </Stack>
      {showFilter && <>
        <Stack direction={'row'} justifyContent={'space-between'} mb={5} spacing={2}
          component={'form'} onSubmit={(e) => {
            e.preventDefault();
            onSubmitSearch();
          }}>
          <TextField
            id="datetime-local"
            label={t('From')}
            type="datetime-local"
            value={dateTimeFrom}
            onChange={(e) => {
              setDateTimeFrom(e.target.value)
            }}
            sx={{ minWidth: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="datetime-local"
            label={t('To')}
            type="datetime-local"
            value={dateTimeTo}
            onChange={(e) => {
              setDateTimeTo(e.target.value)
            }}
            sx={{ minWidth: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
          <Button type={'submit'} variant="outlined">{t('Search')}</Button>
        </Stack>
      </>}
      <BaseTable
        columns={columns}
        query={query}
        forceReloadCounter={forceReloadCounter}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.messageLog.list({
              query: query,
              from: dateTimeFrom ? dateFromInputStringToSql(dateTimeFrom) : null,
              to: dateTimeTo ? dateFromInputStringToSql(dateTimeTo) : null,
            }, page, pageSize)
          } catch (e) {
            console.log('Failed to load message logs', e)
            return []
          }
        }} />
    </Container>
  </Page>)

}