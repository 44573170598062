import { Autocomplete, Box, Checkbox, Chip, Divider, Stack, TextField } from '@mui/material'
import React, { useEffect, useState }                                   from 'react'
import { useApi }                                                       from '../../utils/api'
import Iconify                                                          from '../../components/Iconify'
import { useTranslation }                                               from 'react-i18next'
import { LoadingButton }                                                from '@mui/lab'
import { useSnackbar }                                                  from 'notistack'

const Icon = <Iconify icon={'material-symbols:circle-outline'}/>
const CheckedIcon = <Iconify icon={'material-symbols:check-circle-outline-rounded'}/>

export const ProviderBillingCodes = ({ serviceId, provider, onUpdate }) => {

  const api = useApi()
  const [searchedText, setSearchedText] = useState('')
  const [billCodes, setBillCodes] = useState([])
  const [availableBillCodes, setAvailableBillCodes] = useState([])
  const [saving, setSaving] = useState(false)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    (async () => {
      const billCodes = (await api.billCode.listForService(serviceId)).filter(it => it.providerId === provider.id)
      const availableBillCodes = (await api.billCode.list(provider.id)).data
      setBillCodes(() => billCodes)
      setAvailableBillCodes(() => availableBillCodes)
    })()
  }, [])

  const save = async () => {
    setSaving(() => true)
    const billingCodeIds = billCodes.map(it => it.id)
    try {
      const billCodes = await api.service.saveBillingCodes(serviceId, provider.id, billingCodeIds)
      if (billCodes) {
        onUpdate()
      }
      enqueueSnackbar(t('Billing codes saved'), {
        variant: 'success'
      })
    } catch (e) {
      console.error('Failed to save billing codes',e)
      enqueueSnackbar(t('Failed to save billing codes'), {
        variant: 'error'
      })
    }
    setSaving(() => false)
  }

  return (<Box>
    <Stack direction={'column'} spacing={2}>
      <Divider sx={{ my: 2 }}>
        <Chip label={provider.name} size="small" />
      </Divider>
      {availableBillCodes.length > 0 && <>
        <Autocomplete
          multiple
          id={`provider-autocomplete-${provider.id}`}
          options={availableBillCodes}
          value={billCodes}
          onChange={(event, newValue) => {
            setBillCodes(() => newValue)
          }}
          inputValue={searchedText}
          onClose={(ev, reason) => setSearchedText('')}
          disableCloseOnSelect={true}
          getOptionLabel={(option) => option.externalCode}
          isOptionEqualToValue={(one, other) => one.id === other.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={Icon}
                checkedIcon={CheckedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.externalCode}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params}
                       label={provider.name}
                       onKeyUp={(e) => {
                         if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                           e.preventDefault()
                           setBillCodes((prev) => {
                             const newBillingCodes = availableBillCodes.filter(bc => bc.externalCode.toUpperCase().includes(searchedText.toUpperCase()))
                             const result = [...prev, ...newBillingCodes]
                             return Array.from(new Set(result.map(it => it.id))).map(id => result.find(it => it.id === id))
                           })
                         }
                       }}
                       onChange={(e) => setSearchedText(e.target.value)} />
          )}
        />
        <Box sx={{ mt: 2, px: 1 }}>
          <LoadingButton loading={saving} type={'button'} variant={'contained'} onClick={() => {
            save()
          }}>{t('Save billing codes')}</LoadingButton>
        </Box>
      </>}
    </Stack>
  </Box>)
}