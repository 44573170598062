export const parseCsv = (content, delimiter = ',') => {
 return content
   .trim()
   .split(/\r?\n/)
   .map(line => line.split(delimiter))
}
export const readCsv = (file) => {
 return new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () =>resolve(reader.result)
  reader.onerror = (error) => reject(error)
  reader.readAsText(file)
 })
}