import { useState }    from 'react'

const SESSION_KEY_USER = 'sk.mediatex.user'

const getLocalStorageUser = () => {
  let userJson = localStorage.getItem(SESSION_KEY_USER) ?? null
  return userJson === null ? null : JSON.parse(userJson)
}

let currentUser = getLocalStorageUser()

const setLocalStorageUser = (user) => {
  if (user === null) {
    localStorage.removeItem(SESSION_KEY_USER)
  } else {
    localStorage.setItem(SESSION_KEY_USER, JSON.stringify(user))
  }
}

export const useCurrentUser = (): [Object, Function] => {

  const [user, setUser] = useState(currentUser)

  const setUserInternal = (usr) => {
    currentUser = usr
    setUser(usr)
    setLocalStorageUser(usr)
  }

  // return global userState state and setter function
  return [user, setUserInternal];
}

export function useApi() {

  const BASE_URL = process.env.REACT_APP_API_URL ?? `https://${window.location.host}/api`

  const [user, setCurrentUser] = useCurrentUser()

  const send = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(async (response) => {
          let data = null
          try {
            data = JSON.parse(await response.text())
          } catch (e) {
            data = null
          }
          if (response.status / 100 !== 2) {
            if (response.status === 401) {
              setCurrentUser(null)
              window.location.reload()
            }
            reject({
              ...data,
              status: response.status,
              statusText: response.statusText,
            })
          } else {
            resolve(data)
          }
        })
        .catch(error => {
          console.error(`Api failed on ${options.method.toUpperCase()} ${url}`, error)
          reject(error)
        })
    })
  }

  const encodeURIComponentOwn = (component) => {
    return Array.isArray(component) ? component.map(it => encodeURIComponent(it)).join(',') : encodeURIComponent(component)
  }

  const get = (path, args) => {
    let query = ''
    if (args && Object.getOwnPropertyNames(args).length > 0) {
      query = '?' + (Object.getOwnPropertyNames(args).filter(key => typeof args[key] !== 'undefined' && args[key] !== null).map(key => `${key}=${encodeURIComponentOwn(args[key])}`).join('&'))
    }
    let headers = { }
    if (!!user) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path+query, { method: "get", headers: headers, })
  }

  const put = (path, id, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    if (user !== null) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path+`/${id}`, { method: "put", body: JSON.stringify(data), headers: headers, })
  }

  const post = (path, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    if (user !== null) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path, { method: "post", body: JSON.stringify(data), headers: headers, })
  }

  const _delete = (path) => {
    let headers = { }
    if (!!user) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path, { method: "delete", headers: headers, })
  }

  return {
    user: {
      validateHash: () => get('/user/validateHash', {}),
      login: (email, password) => post('/user/login', { email: email, password: password }),
      logout: () => _delete('/user/logout'),
      detail: (id) => get(`/user/${id}`),
      save: (data) => data.id ? put(`/user`, data.id, data) : post(`/user`, data),
      list: (query, page, count) => get('/user/list', { query: query, page: page, count: count }),
      delete: (id) => _delete(`/user/${id}`)
    },
    category: {
      detail: (id) => get(`/category/${id}`),
      save: (data) => data.id ? put(`/category`, data.id, data) : post(`/category`, data),
      delete: (id) => _delete(`/category/${id}`),
      list: (query, page, count) => get('/category/list', { query: query, page: page, count: count }),
    },
    service: {
      detail: (id) => get(`/service/${id}`),
      save: (data) => data.id ? put(`/service`, data.id, data) : post(`/service`, data),
      delete: (id) => _delete(`/service/${id}`),
      list: (categoryId, query, page, count) => get('/service/list', { categoryId: categoryId, query: query, page: page, count: count }),
      saveBillingCodes: (serviceId, providerId, billingCodeIds) => post(`/service/billingcode/${serviceId}`, { providerId: providerId, billingCodeIds: billingCodeIds }),
    },
    billCode: {
      detail: (id) => get(`/billingcode/${id}`),
      save: (data) => data.id ? put(`/billingcode`, data.id, data) : post(`/billingcode`, data),
      delete: (id) => _delete(`/billingcode/${id}`),
      list: (providerId, query, page, count) => get('/billingcode/list', { providerId: providerId, query: query, page: page, count: count }),
      listForService: (serviceId) => get(`/billingcode/list/service/${serviceId}`, {}),
    },
    provider: {
      detail: (id) => get(`/provider/${id}`),
      save: (data) => data.id ? put(`/provider`, data.id, data) : post(`/provider`, data),
      delete: (id) => _delete(`/provider/${id}`),
      list: (query, page, count) => get('/provider/list', { query: query ?? null, page: page ?? null, count: count ?? null }),
    },
    message: {
      detail: (id) => get(`/message/${id}`),
      list: (params, page, count) => get('/message/list', { ...params, page: page, count: count }),
      logs: (id) => get(`/message/logs/${id}`),
      statistics: (from, to, serviceIds, groupBy) => get(`/message/statistics/${from}-${to}` + (serviceIds ? `/${serviceIds.join(',')}` : ''), {
        groupBy: groupBy?.join(',')
      }),
    },
    messageLog: {
      listForMessage: (msgId) => get(`/messageLog/list/${msgId}`),
      list: (params, page, count) => get('/messageLog/list', { ...params, page: page, count: count }),
    },
    blacklist: {
      detail: (id) => get(`/blacklist/${id}`),
      save: (data) => data.id ? put(`/blacklist`, data.id, data) : post(`/blacklist`, data),
      bulkSave: (data) => post(`/blacklist/save`, data),
      delete: (id) => _delete(`/blacklist/${id}`),
      list: (query, page, count) => get('/blacklist/list', { query: query ?? null, page: page, count: count }),
    },
    whitelist: {
      detail: (id) => get(`/whitelist/${id}`),
      save: (data) => data.id ? put(`/whitelist`, data.id, data) : post(`/whitelist`, data),
      delete: (id) => _delete(`/whitelist/${id}`),
      list: (query, page, count) => get('/whitelist/list', { query: query ?? null, page: page, count: count }),
    }
  }
}