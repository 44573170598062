import React, { useState }            from 'react'
import {
  Box,
  Button,
  Chip,
  Container, Link,
  Stack,
  Typography
}                                     from '@mui/material'
import BaseTable, { BaseSearch }      from '../../components/base-table'
import { useApi }                     from '../../utils/api'
import { Link as RouterLink }         from 'react-router-dom'
import { useTranslation }             from 'react-i18next'
import { dateToUserString, parseUTC } from '../../utils/dateutils'
import Page                           from '../../components/Page'
import Iconify                        from '../../components/Iconify'

export default function CategoriesPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'title', headerName: t('Title'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/dashboard/category/${cell.row.id}`}
        >
          {cell.row.title}
        </Link>
      )},
    { field: 'note', headerName: t('Note'), flex: 1, sortable: false, filterable: false },
  ];

  return (<Page title={t('Categories')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Categories')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/dashboard/category/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New category')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.category.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load categories', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}