import PropTypes                                   from 'prop-types'
import { DataGrid }                                from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { InputAdornment, OutlinedInput }           from '@mui/material'
import styled                                      from '@emotion/styled'
import Iconify                                     from './Iconify'
import { t }                                       from 'i18next'

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const StyledDataGrid = styled(DataGrid) (({ theme }) => ({
  background: theme.palette.common.white
}))


export function BaseSearch(props) {

  const { query, onQueryChanged } = props

  return (<SearchStyle
    value={query}
    onChange={(e) => onQueryChanged(e.target.value) }
    placeholder={t('Search')}
    startAdornment={
      <InputAdornment position="start">
        <Iconify icon="material-symbols:search" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
      </InputAdornment>
    }
  />)
}

BaseSearch.propTypes = {
  query: PropTypes.string.isRequired,
  onQueryChanged: PropTypes.func.isRequired,
}

const BaseTable = React.forwardRef((props, ref) => {

  let { loadCallback, columns, query = '', initialSortModel = [{ field: 'id', sort: 'desc' }], initialPage = 0, forceReloadCounter = 0, initialPageSize = 25, ...otherProps } = props

  if (!loadCallback) {
    throw new Error('Property loadCallback not implemented')
  }

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const [loading, setLoading] = useState(false)
  const [sortModel, setSortModel] = useState(initialSortModel)

  const onPageChange = useCallback((page) => {
    setPage(page)
  }, []);
  const onPageSizeChange = useCallback((pageSize) => {
    setPageSize(pageSize)
  }, []);

  useEffect(() => {
    setPage(0)
  }, [query])

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      let orderBy = sortModel && sortModel[0] ? [[ sortModel[0].field, sortModel[0].sort ]] : []
      const { data, totalCount } = await props.loadCallback(query, page, pageSize, orderBy)
      if (!active) {
        return;
      }

      setRows(data);
      setRowCount(totalCount)
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, query, sortModel, forceReloadCounter]);

  React.useImperativeHandle(ref, () => ({
    getData: () => {
      return rows
    }
  }))

  return (
    <StyledDataGrid
      autoHeight={true}
      rows={rows}
      rowCount={rowCount}
      columns={columns}
      page={page}
      pageSize={pageSize}
      paginationMode={'server'}
      sortingMode={'server'}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      loading={loading}
      rowHeight={32}
      disableSelectionOnClick={true}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => { setSortModel(newSortModel) }}
      {...otherProps}
    />
  );
})

export default BaseTable