import Page                                                         from '../../components/Page'
import CommonStatisticsPage                                         from '../../common/CommonStatisticsPage'
import {
    Container, Typography, TableBody, TableCell,
    TableFooter, TableHead, TableRow,
}                                                                   from '@mui/material'
import { useTranslation }                                           from 'react-i18next'
import { useApi }                                                   from '../../utils/api'
import { fExportPrice, fPrice }                                     from '../../utils/textutils'

const MessageStatisticsPage = () => {

    const api = useApi()
    const { t } = useTranslation()

    return (
        <Page title={t('Message statistics')}>
            <Container component="main">
                <Typography variant="h4" gutterBottom>{t('Message statistics')}</Typography>
                <CommonStatisticsPage
                    fnApiLoadData={api.message.statistics}
                    pathPrefix={'/dashboard/statistics/messages'}
                    defaultGroupBy={['service', 'operator']}
                    allowedGroupBy={['service', 'provider', 'operator', 'status', 'price']}
                    exportFileName={'messages'}
                    exportRow={(row, filter) => {
                        let obj = {}
                        if (filter.groupBy.includes('service')) {
                            obj[t('Service')] = row.service?.name ?? ''
                        }
                        if (filter.groupBy.includes('provider')) {
                            obj[t('Provider')] = row.provider?.name ?? ''
                        }
                        if (filter.groupBy.includes('operator')) {
                            obj[t('Operator')] = row.operator ?? ''
                        }
                        if (filter.groupBy.includes('status')) {
                            obj[t('Status')] = row.status ?? ''
                        }
                        if (filter.groupBy.includes('price')) {
                            obj[t('Price')] = fExportPrice(row.price ?? 0)
                        }
                        obj[t('Count')] = row.count ?? 0
                        obj[t('Sum Price')] = fExportPrice(row.sumPrice ?? 0)
                        return obj
                    }}
                >
                    <StatsTableHeader />
                    <StatsTableBody />
                    <StatsTableFooter />
                </CommonStatisticsPage>
            </Container>
        </Page>)
}

const StatsTableHeader = ({ t, filter }) => {
    return <TableHead>
        <TableRow>
            {filter.groupBy.includes('service') && <TableCell>{t('Service')}</TableCell>}
            {filter.groupBy.includes('provider') && <TableCell>{t('Provider')}</TableCell>}
            {filter.groupBy.includes('operator') && <TableCell>{t('Operator')}</TableCell>}
            {filter.groupBy.includes('status') && <TableCell>{t('Status')}</TableCell>}
            {filter.groupBy.includes('price') && <TableCell>{t('Price')}</TableCell>}
            <TableCell align={'right'}>{t('Count')}</TableCell>
            <TableCell align={'right'}>{t('Sum Price')}&nbsp;€</TableCell>
        </TableRow>
    </TableHead>
}

const StatsTableBody = ({ t, filter, data }) => {
    return <TableBody>
        {data.map((row, i) => <TableRow key={`row-${i}`}>
            {filter.groupBy.includes('service') && <TableCell>{row.service?.name ?? ''}</TableCell>}
            {filter.groupBy.includes('provider') && <TableCell>{row.provider?.name ?? ''}</TableCell>}
            {filter.groupBy.includes('operator') && <TableCell>{row.operator ?? ''}</TableCell>}
            {filter.groupBy.includes('status') && <TableCell>{ t(row.status) ?? ''}</TableCell>}
            {filter.groupBy.includes('price') && <TableCell>{fPrice(row.price ?? 0)}</TableCell>}
            <TableCell align={'right'}>{row.count ?? 0}</TableCell>
            <TableCell align={'right'}>{fPrice(row.sumPrice ?? 0)}</TableCell>
        </TableRow>)}
    </TableBody>
}

const StatsTableFooter = ({ t, filter, data }) => {
    const sum = (prop) => data.map((row) => row[prop] ?? 0).reduce((acc, curr) => acc + curr, 0)
    const countOfEmptyCells = ['service', 'provider', 'operator', 'status', 'price'].map(groupBy => filter.groupBy.includes(groupBy)).filter(it => it).length - 1

    return <TableFooter>
        <TableRow>
            <TableCell component={'th'} scope="row">{t('Total')}</TableCell>
            {Array(countOfEmptyCells).fill().map((_, i) => <TableCell key={`cell-${i}`}>&nbsp;</TableCell>)}
            <TableCell align={'right'}>{sum('count')}</TableCell>
            <TableCell align={'right'}>{fPrice(sum('sumPrice'))}</TableCell>
        </TableRow>
    </TableFooter>
}

export default MessageStatisticsPage