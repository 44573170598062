import React, { useState }            from 'react'
import {
  Box,
  Button,
  Container, Link,
  Stack,
  Typography
}                                from '@mui/material'
import BaseTable, { BaseSearch } from '../../components/base-table'
import { useApi }                from '../../utils/api'
import { Link as RouterLink }    from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import Page                      from '../../components/Page'
import Iconify                   from '../../components/Iconify'

export default function Providers() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 50, sortable: false, filterable: false },
    { field: 'uid', headerName: t('UID'), width: 100, sortable: false, filterable: false, renderCell: (service) => {
        return (
          <Box component="span" sx={{ letterSpacing: '1.5px', fontSize: '.80em', fontWeight: '500' }}>
            {service.row.uid}
          </Box>
        )
      } },
    { field: 'name', headerName: t('Name'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/dashboard/providers/${cell.row.id}`}
        >
          {cell.row.name}
        </Link>
      ) },
    { field: 'type', headerName: t('Type'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => {
      return t(`provider-type-${cell.row.type}`)
      } },
  ];

  return (<Page title={t('Providers')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Providers')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/dashboard/providers/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New provider')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.provider.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load providers', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}