import { useRef, useState }                 from 'react'
// material
import { alpha }                            from '@mui/material/styles'
import { Box, MenuItem, Stack, IconButton } from '@mui/material'
// components
import MenuPopover                          from '../../components/MenuPopover'
import Iconify                              from '../../components/Iconify'
import { useTranslation }                   from 'react-i18next'
import i18n                                 from '../../i18n'

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en-GB',
    label: 'English',
    icon: 'flagpack:gb-ukm',
  },
  {
    value: 'sk-SK',
    label: 'Slovenčina',
    icon: 'flagpack:sk',
  },
]

// ----------------------------------------------------------------------

export default function LanguagePopover () {

  const { t } = useTranslation()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [lang, setLang] = useState(i18n.language)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={LANGS.find(l => l.value === lang)?.icon ?? 'flagpack:gb-ukm'}/>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === lang} onClick={() => {
              handleClose()
              i18n.changeLanguage(option.value)
              setLang(option.value)
            }} disabled={!!option.disabled}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Iconify icon={option.icon}/>
                <span>{option.label}</span>
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
