import React, { useEffect, useState, useRef }              from 'react'
import { useTranslation }                                  from 'react-i18next'
import {
    Button, Container, Grid,
    Paper, Stack, Typography, FormControlLabel,
    Checkbox, FormControl, Radio, RadioGroup,
}                                                          from '@mui/material'
import AlertSimpleDialog                                   from '../../components/alert-simple-dialog'
import { useApi }                                          from '../../utils/api'
import { useNavigate, useParams }                          from 'react-router-dom'
import { useSnackbar }                                     from 'notistack'
import ShortFormControl                                    from '../../components/short-form-control'
import Page                                                from '../../components/Page'
import { parseCsv, readCsv }                               from '../../utils/fileutils'

export default function BlacklistItemPage() {

    const api = useApi()
    const navigate = useNavigate()
    const { blacklistItemId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [valuesFromSingleEntry, setValuesFromSingleEntry] = useState({
        telNumber: '',
        note: '',
    })
    const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
    const [selectedImportType, setSelectedImportType] = useState('singleEntry')
    const [telNumbersFromCsv, setTelNumbersFromCsv] = useState([])
    const fileInputRef = useRef(null)

    useEffect(() => {
        (async () => {
            if (!isNaN(blacklistItemId)) {
                let item = await api.blacklist.detail(blacklistItemId).catch(() => ({}))
                setValuesFromSingleEntry({
                    ...item,
                })
                if (item.categoryIds) {
                    setSelectedCategoryIds(item.categoryIds)
                }
            }
        })()
    }, [blacklistItemId])

    useEffect(() => {
        (async () => {
            try {
                const categories = await api.category.list()
                setCategories(categories?.data ?? [])
            } catch (e) {
                console.error('Failed to fetch categories:', e)
                setCategories([])
            }
        })()
    }, [])

    const handleCloseDeleteDialog = (result) => {
        setShownDeleteDialog(false)
        if (result) {
            api.blacklist.delete(blacklistItemId).then(() => {
                enqueueSnackbar(t('Item deleted from blacklist'), {
                    variant: 'success'
                })
                navigate('/dashboard/blacklist')
            }).catch(error => {
                enqueueSnackbar(t('Failed to delete item from blacklist'), {
                    variant: 'error'
                })
            })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (selectedImportType === 'singleEntry' && !valuesFromSingleEntry.telNumber) {
            enqueueSnackbar(t('Enter a phone number'), {
                variant: 'warning'
            })
            return
        }
        if (selectedImportType === 'csvImport' && telNumbersFromCsv.length === 0) {
            enqueueSnackbar(t('Upload a file'), {
                variant: 'warning'
            })
            return
        }
        try {
            if (selectedImportType === 'csvImport') {
                await api.blacklist.bulkSave({
                    telNumbers: telNumbersFromCsv,
                    categoryIds: selectedCategoryIds,
                })
                navigate(`/dashboard/blacklist`)
            } else {
                const item = await api.blacklist.save({
                    ...valuesFromSingleEntry,
                    categoryIds: selectedCategoryIds,
                })
                navigate(`/dashboard/blacklist/${item.id}`)
            }
            enqueueSnackbar(t(`Blacklist ${selectedImportType === 'csvImport' ? 'items' : 'item'} saved`), { variant: 'success' })
        } catch (e) {
            fileInputRef.current.value = null
            setTelNumbersFromCsv(() => [])
            enqueueSnackbar(e?.error || t('Failed to import blacklist items'), {
                variant: "error",
            })
        }
    }

    const handleSingleEntryChange = (prop) => (event) => {
        setValuesFromSingleEntry((prevValues) => ({
            ...prevValues,
            [prop]: event.target.value
        }))
    }

    const handleRadioChange = (event) => {
        setSelectedImportType(event.target.value)
    }
    const handleCheckboxChange = (categoryId) => (event) => {
        setSelectedCategoryIds((prevCategoryIds) =>
            event.target.checked ? [...prevCategoryIds, categoryId] : prevCategoryIds.filter(id => id !== categoryId)
        )
    }

    const handleFileChange = async () => {
        if (!fileInputRef.current) {
            return
        }
        const selectedFile = fileInputRef.current?.files?.[0]
        if (!selectedFile || selectedFile.type !== "text/csv") {
            enqueueSnackbar(t('Invalid csv file'), { variant: 'error' })
            fileInputRef.current.value = null
            return
        }
        try {
            const csvContent = await readCsv(selectedFile)
            const parsedCsv = parseCsv(csvContent)
            const telNumbers = parsedCsv.map(row => row[0])
            setTelNumbersFromCsv(telNumbers)
        } catch (e) {
            enqueueSnackbar(t('Error reading the file'), { variant: 'error' })
        }
    }

    return (
        <Page title={t('Blacklist item')}>
            <Stack direction="row" alignItems="center" mb={5} spacing={2}>
                <Typography variant="h4" gutterBottom>{t('Blacklist item')}</Typography>
            </Stack>
            <Container component={Paper} sx={{p: 2,}}>
                <Grid container component={'form'} onSubmit={onSubmit} spacing={2}>
                    {!blacklistItemId &&
                      <Grid item xs={12}>
                          <FormControl>
                              <RadioGroup name="radioImportType"
                                          row
                                          value={selectedImportType}
                                          ref={(input) => input && (input.value = "")}
                                          onChange={handleRadioChange}>
                                  <FormControlLabel value={'singleEntry'} control={<Radio />} label={t("Single entry")} />
                                  <FormControlLabel value={'csvImport'} control={<Radio />} label={t("Csv import")} />
                              </RadioGroup>
                          </FormControl>
                      </Grid>
                    }
                    {selectedImportType && selectedImportType === 'singleEntry' &&
                      <>
                          <Grid item xs={12}>
                              <ShortFormControl
                                name={'telNumber'}
                                title={t('Tel. number')}
                                value={valuesFromSingleEntry.telNumber}
                                handleChange={handleSingleEntryChange}/>
                          </Grid>
                        <Grid item xs={12}>
                            <ShortFormControl
                                name={'note'}
                                title={t('Note')}
                                value={valuesFromSingleEntry.note}
                                handleChange={handleSingleEntryChange}/>
                        </Grid>
                      </>
                    }
                    {selectedImportType && selectedImportType === 'csvImport' &&
                      <Grid item xs={12}>
                          <Button variant="contained" component="label">
                              <input
                                type="file"
                                accept=".csv"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                              />
                          </Button>
                      </Grid>
                    }
                    <Grid item xs={12}>
                        {categories.map((cat) => (
                          <FormControlLabel key={cat.id} label={cat.title} control={<Checkbox checked={selectedCategoryIds.includes(cat.id)} onChange={handleCheckboxChange(cat.id)}/>}/>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
                        {!isNaN(blacklistItemId) && (
                            <Button type={'button'} color={'error'} variant={'contained'} sx={{ml: 2}}
                                    onClick={() => {
                                        setShownDeleteDialog(true)
                                    }}>{t('Delete')}</Button>)}
                    </Grid>
                </Grid>
                <AlertSimpleDialog
                    title={t('Remove item from blacklist')}
                    message={t('delete-confirm-msg', {name: valuesFromSingleEntry.telNumber})}
                    open={shownDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                />
            </Container>
        </Page>
    )

}