import React, { useCallback, useEffect, useState }                        from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel, MenuItem, Paper, Select,
  Stack, Table, TableContainer,
  TextField
}                                                                         from '@mui/material'
import { Trans, useTranslation }                                          from 'react-i18next'
import Typography                                                         from '@mui/material/Typography'
import { useParams }                                                      from 'react-router-dom'
import palette                                                            from '../theme/palette'
import { useApi }                                                         from '../utils/api'
import { useSnackbar }                                                    from 'notistack'
import { DateTime }                                                       from 'luxon'
import Loader                                                             from '../components/Loader'
import ShortFormControl                                                   from '../components/short-form-control'
import Iconify                                                            from '../components/Iconify'
import { utils, writeFile }                                               from 'xlsx'

const CommonStatisticsPage = ({ fnApiLoadData, exportRow, pathPrefix, allowedGroupBy, defaultGroupBy, customOptions, exportFileName, children }) => {

  const api = useApi()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { serviceIds, from, to, groupBy } = useParams()
  const [filter, setFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    selectedServices: [],
    groupBy: groupBy?.split(',') ?? defaultGroupBy,
  })
  const [shownFilter, setShownFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    selectedServices: [],
    groupBy: groupBy?.split(',') ?? defaultGroupBy,
  })
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [services, setServices] = useState([])
  const [filterOpen, setFilterOpen] = useState(true)

  useEffect(() => {
    api.service.list().then(({ data }) => {
      setServices(data)
      const selectedServices = (serviceIds ?? '').split(',')?.map(it => parseInt(it))?.map(serviceId => data.find(it => it.id === serviceId))?.filter(it => !!it) ?? []
      setFilter((prev) => ({
        ...prev,
        selectedServices: selectedServices
      }))
      setShownFilter((prev) => ({
        ...prev,
        selectedServices: selectedServices
      }))
    }).catch(e => {
      enqueueSnackbar(t('Failed to load services'), {
        variant: 'error'
      })
    })
  }, [])

  const onSubmit = (event) => {
    event?.preventDefault()
    // first update history token
    const urlPath = `${pathPrefix}/${filter.dateFrom}/${filter.dateTo}` + `/${filter.groupBy.join(',')}` + (filter.selectedServices.length > 0 ? `/${filter.selectedServices.map(it => it.id).join(',')}` : '')
    window.history.replaceState(null, t('Statistics'), urlPath)
    // load data
    setLoading(() => true)
    fnApiLoadData(
        filter.dateFrom?.replaceAll('-', ''),
        filter.dateTo?.replaceAll('-', ''),
        filter.selectedServices?.map(it => it.id),
        filter.groupBy
    ).then((data) => {
      setItems(data)
    }).catch(e => {
      enqueueSnackbar(t('Failed to load statistics'), {
        variant: 'error'
      })
    }).finally(() => {
      setShownFilter(() => ({ ...filter }))
      setLoading(() => false)
    })
  }

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(items.map(it => exportRow(it, shownFilter)))
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, "Data")
    writeFile(wb, `${exportFileName}-${shownFilter.dateFrom.replaceAll('-', '')}-${shownFilter.dateTo.replaceAll('-', '')}.xlsx`)
  }, [items])

  const definedGroupKeys = {
    'service': t('GroupService'),
    'provider': t('GroupProvider'),
    'operator': t('GroupOperator'),
    'status': t('GroupStatus'),
    'price': t('GroupPrice')
  }

  const groupKeys = Object.getOwnPropertyNames(definedGroupKeys).filter(it => allowedGroupBy.indexOf(it)>-1).reduce((acc,curr) => {
    acc[curr] = definedGroupKeys[curr]
    return acc
  }, {})

  const makeFilterTitle = () => {
    const reqDateFrom = (shownFilter.dateFrom ? DateTime.fromFormat(shownFilter.dateFrom, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqDateTo = (shownFilter.dateTo ? DateTime.fromFormat(shownFilter.dateTo, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqServices = shownFilter.selectedServices.length === 0 ? t('all services') : shownFilter.selectedServices.map(it => it.name).join(', ')
    const reqGroupBy = (shownFilter.groupBy ?? ['service']).map(key => groupKeys[key]).join(', ')

    return <Trans i18nKey={'statisticsHighlight'} values={{ from: reqDateFrom, to: reqDateTo, services: reqServices, groupBy: reqGroupBy }} />
  }

  return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2} alignItems={'baseline'} mb={2}>
              <Typography variant={'h6'}>{t('Filter')}</Typography>
              <Typography variant="body1" sx={{ color: palette.text.disabled }}>
                {makeFilterTitle()}
              </Typography>
              <Loader loading={loading} direction={'row'} size={'1.5em'} justifyContent={'start'} />
              <Box flexGrow={1} />
              <IconButton onClick={(e) => setFilterOpen(prev => !prev)}>
                <Iconify icon={filterOpen ? 'material-symbols:filter-alt-outline' : 'material-symbols:filter-alt'} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Collapse in={filterOpen}>
          <Grid container component={'form'} onSubmit={onSubmit} spacing={2} rowSpacing={2} direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
            <Grid item xs={6} sm={'auto'}>
              <ShortFormControl title={t('From')} handleChange={(name) => (e) => { setFilter((prev) => ({
                ...prev,
                dateFrom: e.target?.value
              })) }} value={filter.dateFrom} name={'from'} inputProps={{ type: 'date' }} />
            </Grid>
            <Grid item xs={6} sm={'auto'}>
              <ShortFormControl title={t('To')} handleChange={(name) => (e) => { setFilter((prev) => ({
                ...prev,
                dateTo: e.target?.value
              })) }} value={filter.dateTo} name={'to'} inputProps={{ type: 'date' }} />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="label-group-by">{t('Group by')}</InputLabel>
                <Select
                    labelId="label-group-by"
                    id="select-group-by"
                    multiple
                    value={filter.groupBy}
                    onChange={(e) => setFilter((prev) => ({
                      ...prev,
                      groupBy: e.target.value.length === 0 ? ['service'] : e.target.value
                    }))}
                    label={t('Group by')}
                >
                  {Object.getOwnPropertyNames(groupKeys).map(groupKey => (<MenuItem key={groupKey} value={groupKey}>{groupKeys[groupKey]}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={'auto'} sx={{ minWidth: { sm: '200px' } }}>
              <Autocomplete
                  multiple
                  fullWidth
                  id="tags-services"
                  options={services}
                  getOptionLabel={(option) => option.name}
                  value={filter.selectedServices}
                  onChange={(e, newValue) => {
                    setFilter((prev) => ({
                      ...prev,
                      selectedServices: newValue
                    }))
                  }}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="standard"
                          label={t('Services')}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={12} sx={{ maxWidth: { sm: '200px' } }}>
              <Button fullWidth type={'submit'} variant="outlined">{t('Confirm')}</Button>
            </Grid>
          </Grid>
        </Collapse>
        {customOptions && <>{customOptions}</>}
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            {React.Children.map(children, child => React.cloneElement(child, { t: t, filter: shownFilter, data: items }))}
          </Table>
        </TableContainer>
        {exportRow && <Stack alignItems={'end'} mt={2}>
          <Button
              type={'button'}
              variant="outlined"
              endIcon={<Iconify icon={'material-symbols:download'} />}
              onClick={(e) => exportFile()}
          >{t('Export')}</Button>
        </Stack>}
      </> )
}
export default CommonStatisticsPage