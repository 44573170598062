import PropTypes                        from 'prop-types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { t }                            from 'i18next'
import Typography                from '@mui/material/Typography'

// ----------------------------------------------------------------------

export default function Loader (props) {

  const { loading, children } = props

  if (loading) {
    return (<Stack direction={'column'} spacing={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress />
      <Typography variant={'caption'}>{t('Loading...')}</Typography>
    </Stack>)
  }

  return (<>{children}</>)
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired
}